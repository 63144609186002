export const EN = 'en';
export const supportedLanguages = [EN] as const;
export const currentYear = new Date().getFullYear();
export const dateAndCompany = `© ${currentYear} Little Emperors`;

export const navigationPaths = {
  HOMEPAGE: '/',
  MEMBERSHIP: '/membership',
  PRESS: '/press',
  PRESS_DETAIL: '/press/:id',
  PRESS_LIST: '/press/list',
  THE_TEAM: '/the-team',
  CAREERS: '/careers',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  FAQ: '/faq',
  OUR_HOTELS: '/our-hotels',
  ABOUT: '/about',
  PRIVACY_POLICY: '/privacy-policy',
  SEARCH_RESULTS: '/search-results',
  INSPIRATIONS: '/inspirations',
  E_GIFT_CARD: '/e-gift-card',
  E_GIFT_CARD_REDEEM: '/e-gift-card/redeem',
  E_GIFT_CARD_REDEEM_INTERNAL: `${process.env.NEXT_PUBLIC_WEBAPP_URL}/ile/account/redeem-your-e-gift-card?redirectTo=/e-gift-card/redeem`,
  DISCOVER_MYLER: '/discover-myler',
  DISCOVER_MYLER_ENQUIRE: '/discover-myler#enquire',
  TRAVEL_JOURNAL_ARTICLES: '/travel-journal',
  TRAVEL_JOURNAL_ARTICLE: '/travel-journal/:slug',
  TRAVEL_JOURNAL_ACTIVITIES: '/travel-journal/activities',
  TRAVEL_JOURNAL_BESTOF: '/travel-journal/best-of',
  TRAVEL_JOURNAL_DESTINATIONS: '/travel-journal/destinations',
  TRAVEL_JOURNAL_EXPERIENCES: '/travel-journal/experiences',
  TRAVEL_JOURNAL_FAMILY: '/travel-journal/family',
  TRAVEL_JOURNAL_FOODDRINK: '/travel-journal/food-drink',
  TRAVEL_JOURNAL_INSPIRATION: '/travel-journal/inspiration',
  TRAVEL_JOURNAL_WELLNESS: '/travel-journal/wellness',
  PARTNER_WITH_US: '/partner-with-us',
  HOTEL_PAGE_INTERNAL: `${process.env.NEXT_PUBLIC_WEBAPP_URL}/ile/hotel/:id?redirectTo=/our-hotels/:id`,
  MYLER_DIRECTORY: '/myler-directory',
  NOT_FOUND: '/not-found',
};

export const supportedNavigationPaths = [
  navigationPaths.HOMEPAGE,
  navigationPaths.MEMBERSHIP,
  navigationPaths.PRESS,
  navigationPaths.THE_TEAM,
  navigationPaths.CAREERS,
  navigationPaths.TERMS_AND_CONDITIONS,
  navigationPaths.FAQ,
  navigationPaths.OUR_HOTELS,
  navigationPaths.ABOUT,
  navigationPaths.PRIVACY_POLICY,
  navigationPaths.SEARCH_RESULTS,
  navigationPaths.INSPIRATIONS,
  navigationPaths.E_GIFT_CARD,
  navigationPaths.E_GIFT_CARD_REDEEM,
  navigationPaths.TRAVEL_JOURNAL_ARTICLES,
] as const;

export const externalNavigationPaths = {
  INSTAGRAM: 'https://www.instagram.com/littleemperors/',
  FACEBOOK: 'https://www.facebook.com/LittleEmperors/',
  TWITTER: 'https://twitter.com/littleemperors',
  WHATSAPP:
    'https://api.whatsapp.com/send/?phone=442081768552&text&type=phone_number&app_absent=0',
  APP_STORE: 'https://apps.apple.com/app/myle/id1060472608',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.littleemperors.myle',
};

export const phoneNumbers = {
  UK: '+44 020 3582 4880',
  FR: '+33 9 70 01 90 21',
  US: '+1 800 704 5715',
  AU: '+61 2 9158 3268',
};

export const emailAdresses = {
  JOB_APPLICATION_RECIPIENT: 'talent@littleemperors.com',
  MEMBERSHIP: 'membership@littleemperors.com',
  PARTNER_WITH_US: 'partnerships@littleemperors.com',
};

export const externalLinkTitles = {
  INSTAGRAM_TITLE: 'Instagram icon',
  FACEBOOK_TITLE: 'Facebook icon',
  TWITTER_TITLE: 'Twitter icon',
  WHATSAPP_TITLE: 'Whatsapp icon',
  APP_STORE_TITLE: 'Download on the App Store',
  GOOGLE_PLAY_TITLE: 'Get it on Google Play',
};

export const SIGN_IN_DIALOG = 'SIGN_IN_DIALOG';
export const FORGOT_PASSWORD_DIALOGUE = 'FORGOT_PASSWORD_DIALOGUE';
export const RESET_PASSWORD_DIALOGUE = 'RESET_PASSWORD_DIALOGUE';
export const CHECK_YOUR_EMAIL_DIALOGUE = 'CHECK_YOUR_EMAIL_DIALOGUE';
export const JOIN_US_DIALOG_STEP_1 = 'JOIN_US_DIALOG_STEP_1';
export const JOIN_US_DIALOG_STEP_2 = 'JOIN_US_DIALOG_STEP_2';
export const JOIN_US_DIALOG_STEP_3 = 'JOIN_US_DIALOG_STEP_3';
export const REGISTRATION_SUCCESS_DIALOGUE = 'REGISTRATION_SUCCESS_DIALOGUE';
export const MOBILE_PARTNER_MENU_DIALOGUE = 'MOBILE_PARTNER_MENU_DIALOGUE';
export const MOBILE_MENU_DIALOGUE = 'MOBILE_MENU_DIALOGUE';
export const TERMS_AND_CONDITIONS_DIALOGUE = 'TERMS_AND_CONDITIONS_DIALOGUE';
export const NEWSLETTER_SUBSCRIPTION_MODAL = 'NEWSLETTER_SUBSCRIPTION_MODAL';
export const COOKIE_CONSENT_MODAL = 'COOKIE_CONSENT_MODAL';

export const WEB_APP_ROUTES = [
  'account',
  'account/redeem-your-e-gift-card',
] as const;

export const supportedDialogues = [
  SIGN_IN_DIALOG,
  FORGOT_PASSWORD_DIALOGUE,
  RESET_PASSWORD_DIALOGUE,
  CHECK_YOUR_EMAIL_DIALOGUE,
  JOIN_US_DIALOG_STEP_1,
  JOIN_US_DIALOG_STEP_2,
  JOIN_US_DIALOG_STEP_3,
  MOBILE_PARTNER_MENU_DIALOGUE,
  MOBILE_MENU_DIALOGUE,
  TERMS_AND_CONDITIONS_DIALOGUE,
  NEWSLETTER_SUBSCRIPTION_MODAL,
  COOKIE_CONSENT_MODAL,
  REGISTRATION_SUCCESS_DIALOGUE,
] as const;

export const breakpoints = {
  xl: '1650px',
  lg: '1280px',
  md: '1024px',
  sm: '450px',
  xs: '375px',
};

export const colorConstants = {
  white: '#fff',
  black: '#191919',
  brandColor: '#BAA57E',
  gray: '#505050',
  lightGray: '#B2B2B2',
};

export const sessionStorageKeys = {
  NEWSLETTER_MODAL_OPENED: 'NEWSLETTER_MODAL_OPENED',
  COOKIE_CONSENT_MODAL_OPENED: 'COOKIE_CONSENT_MODAL_OPENED',
};

export const urlQueryParamKeys = {
  RESET_PASSWORD_TOKEN: 'reset-password-token',
};

export const webserviceEndpoints = {
  SUBSCRIBE_TO_NEWSLETTER: '/api/newsletter',
  COUPON: '/api/coupons',
  PRICES: '/api/prices',
  TOKEN: '/api/token',
  FORGOT_PASSWORD: '/api/forgot-password',
  RESET_PASSWORD: '/api/reset-password',
  USER: '/api/user',
  FAKE: '/api/fake',
  HOT: '/hotels/hot',
  LE_COUPON: '/coupons',
  LE_PRESS_SAY: '/homepage/press-quotes',
  LE_TESTIMONIALS: '/homepage/testimonials',
  LE_COUNTRY_CODE: '/countries',
  LE_SUBSCRIBE_TO_NEWSLETTER: '/newsletter',
  LE_PRICES: '/prices',
  LE_HOME_PAGE_OUR_HOTELS: '/homepage/our-hotels',
  LE_HOME_HERO_ELEMENTS: '/homepage/images',
  LE_PRESS_LIST: '/press-releases',
  LE_USERS_LOGIN: '/users/login',
  LE_FORGOT_PASSWORD: '/password/email',
  LE_RESET_PASSWORD: '/password/reset',
  LE_USERS: '/users',
  LE_HOME_PAGE_GET_OUR_PARTNERS: '/homepage/partners',
  LE_THE_TEAM: '/team',
  LE_CAREERS: '/careers',
  LE_HOTELS: '/hotels/',
  LE_INSTAGRAM_DATA: '/instagram-feed',
  LE_INSPIRATIONS: '/hotel-inspirations',
  PARTNERS: '/partners',
  SEARCH_RESULTS: 'hotels',
  MYLER_LEADS: '/api/mylers/leads',
  TRAVEL_JOURNAL_ARTICLES: '/travel-journal/articles',
  CUSTOM_GIFT_CARDS: '/custom-gift-cards',
};

export const elementIds = {
  WHY_LITTLE_EMPERORS_SECTION_ID: 'WHY_LITTLE_EMPERORS_SECTION_ID',
};

export const HERO_SECTION_TIME_INTERVAL = 6000; // 6 sec

// Used to get static paths. A limited number of list element pages will be prerendered at build time
export const MAX_PRERENDERED_LIST_ITEMS = 10;

// Cache time of the pages. After this intervall the pages will be invalidated and regenerated with updated data
export const PAGE_INVALIDATION_INTERVAL = process.env.PAGE_INVALIDATION_INTERVAL
  ? parseInt(process.env.PAGE_INVALIDATION_INTERVAL)
  : 60; // 1 min

export const membershipOptions = {
  TRAVEL: 'travel',
  GOLD: 'gold',
  BLACK: 'black',
  BLACK_PLUS: 'black+',
  DYNASTY: 'dynasty',
  DYNASTY_DINING: 'dynasty dining',
};

export const supportedMemberships = [
  membershipOptions.TRAVEL,
  membershipOptions.GOLD,
  membershipOptions.BLACK,
  membershipOptions.BLACK_PLUS,
  membershipOptions.DYNASTY,
] as const;

export const supportedCurrencyCodes = ['GBP', 'EUR', 'USD'] as const;

export const membershipCategoryOptions = {
  CORPORATE: 'Corporate' as const,
  LEISURE: 'Leisure' as const,
};

export const supportedMembershipCategories = [
  membershipCategoryOptions.CORPORATE,
  membershipCategoryOptions.LEISURE,
] as const;

export const ARTICLE_DATE_FORMAT = 'MMMM DD, YYYY';
export const CREDIT_CARD_DATE_FORMAT = 'MM-YY';
export const USER_BIRTH_DAY_DATE_FORMAT = 'DD/MM';
export const DATE_FORMAT_REQUEST = 'YYYY-MM-DD';

export const CARD_BRAND_DEFAULT = 'DEFAULT_CARD_BRAND';
export const CARD_BRAND_VISA = 'VISA';
export const CARD_BRAND_AMEX = 'AMEX';
export const CARD_BRAND_MASTERCARD = 'MASTERCARD';
export const CARD_BRAND_DISCOVER = 'DISCOVER';
export const CARD_BRAND_DINERS = 'DINERS';
export const CARD_BRAND_JCB = 'JCB';
export const CARD_BRAND_CHINA_UNION_PAY = 'CHINA_UNION_PAY';

export const supportedCreditCardbrands = [
  CARD_BRAND_DEFAULT,
  CARD_BRAND_VISA,
  CARD_BRAND_AMEX,
  CARD_BRAND_MASTERCARD,
  CARD_BRAND_DISCOVER,
  CARD_BRAND_DINERS,
  CARD_BRAND_JCB,
  CARD_BRAND_CHINA_UNION_PAY,
] as const;

export const defaultCurrencyCode = 'GBP';
